/*** footer start ***/
.footer {
    background: var(--bs-dark);
}

.footer .footer-item a,
.footer .footer-item p {
    color: var(--bs-white);
    line-height: 40px;
    font-size: 17px;
    transition: 0.5s;
    display: block !important;
}

.footer .footer-item a:hover {
    letter-spacing: 2px;
    color: var(--bs-primary) !important;
}
.footer .footer-item .nav-link {
    display: block !important;
}
/*** Footer End ***/