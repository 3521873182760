/*** Services Start ***/
.services .services-item {
    transition: 0.5s;
}
.service{
    color: var(--main-color);
    font-weight: bold;
}
.detaylar {
    color: #001B38 !important;
    border-color: #001B38 !important;
}
.services-item {
    border-color: #001f3f !important;
}
.services .services-item:hover {
    background: var(--main-color) !important;
    border-color: #001f3f !important;
}
.services-head {
    color: #001B38;
}
.services .services-item:hover .services-content p,
.services .services-item:hover .services-content h3 {
    color: var(--bs-white);
    transition: 0.5s;
}

.services .services-item:hover .services-content a.btn {
    background: var(--bs-white);
    color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}

.services .services-item:hover .services-content a.btn:hover {
    color: #001B38 !important;
    border-color: var(--main-color);
}

.services .services-item .services-img {
    overflow: hidden;
}

.services .services-item .services-img img {
    transition: 0.5s;
}

.services .services-item .services-img img:hover {
    transform: scale(1.3);
}
/*** Services End ***/