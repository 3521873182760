.custom-image {
  height: 600px;
  width: auto;
  max-width: 100%;
}
.head {
  color: #9d8221;
}
.lazer-container {
  overflow: hidden;
}

@media (max-width: 992px) {
  .custom-image {
    height: auto;
  }
}
