
.sticky-top {
    transition: 0.5s;
    background: var(--bs-white);
}

.topbar {
    padding: 10px 0;
    background: rgb(0,27,56)!important;

}
.nav-whats{
    text-decoration: none;
    color: #080700;
    font-weight: bold;
    /* --animate-duration: 800ms; */
    animation-delay: 2s;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #9D8221;
    color: aliceblue !important;
    opacity: 0.5;
}
.topbar-container {
    color: #9D8221 !important;
    border-color: #9D8221 !important;
}
.links {
    color: #9D8221;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
}
/*** Navbar Start ***/
.navbar .navbar-nav .nav-link {
    padding: 10px 15px;
    font-size: 16px;
    transition: .5s;
}

/* .navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active,
.sticky-top.bg-white .navbar .navbar-nav .nav-link:hover,
.sticky-top.bg-white .navbar .navbar-nav .nav-link.active {
    color: var(--main-color) !important;
}  */

.img-logo {
    width: 226px;
    height: 95px;
}
.button {
    background-color:#9D8221 !important;
    color: white;
    border-color: white;
}
.button:hover {
    background-color: #9D8221 !important;
    color: white;

}
@media (min-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        transition: .5s;
        opacity: 0;
    }
}
.navbar-nav a {
    color: #9D8221 !important;
}

.dropdown .dropdown-menu a:hover {
    background: white !important;
    color: gold !important;
}

.navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light) !important;
    border-radius: 10px !important;
    transition: .5s;
    opacity: 1;
}
.dropdown-toggle::after {
    display: none !important;
}
.bg-white {
    background-color: transparent !important;
}
.navbar-container {
    padding: 0 !important;
}
.adress {
    text-decoration: none;
}
.nav-hover:hover {
    opacity: 0.5;
}
@media (min-width: 1200px) {
    .navbar .navbar-collapse .border-top {
        border-top: none !important;
    }
}

#searchModal .modal-content {
    background: rgba(250, 250, 250, .6);
}
@media (max-width: 767px) {
    .links {
      font-size: 12px; 
    }
    .img-logo{
        width: 145px;
        height: 65px;
        padding-left: 11px;
    }
    .top-navv {
        display: none;
    }
  }
/*** Navbar End ***/