@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,600;1,600&family=IBM+Plex+Sans+Arabic:wght@400;500&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@1,600&family=Roboto+Condensed:ital,wght@0,400;0,700;1,400&family=Roboto:wght@300;400;500;700;900&family=Rubik:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500;600;700;800;900&display=swap');
:root{
  --main-font:'Roboto Slab', serif;;
  --main-color:rgb(157,130,33);
  }
body {
  margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafc !important;
  overflow-x: hidden
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
