/*** Contact Start ***/
.contact {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../Images/openhand_8_8.cur);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact .contact-form {
    background: rgba(255, 255, 255, 0.6);
}
.contact-foot {
    background-color: #001B38 !important;
}
.contact-infos {
    padding: 32px !important;
}
.contact-infoss {
    padding: 43px !important;
}
/*** Contact End ***/