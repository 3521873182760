/*** Carousel Start ***/
.carousel-item {
  position: relative;
  min-height: 100vh;
}
.car-text {
  /* color:var(--main-color) !important; */
  color: #001b38 !important;
}
.contact-now {
  color: var(--main-color);
}
.carousel-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#carouselId ul {
  list-style: none;
}
.carousel-item .carousel-caption {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, 0.5)); */
  background: rgba(33, 30, 28, 0.7);
  background-size: cover;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 0;
}

.carousel-control-prev .carousel-control-prev-icon,
.carousel-control-next .carousel-control-next-icon {
  background: transparent;
}

#carouselId .carousel-indicators {
  padding-bottom: 30px;
}

#carouselId .carousel-indicators li {
  border-top: 10px solid var(--bs-white);
  border-bottom: 10px solid var(--bs-white);
  border-right: 5px solid var(--bs-white);
  border-left: 5px solid var(--bs-white);
  margin-right: 10px;
  border-radius: 10px;
  transition: 0.5s;
}

#carouselId .carousel-indicators li.active {
  background: var(--main-color);
  border-color: var(--main-color);
  border-right: 15px solid var(--main-color);
  border-left: 15px solid var(--main-color);
}
/*** Carousel End ***/
