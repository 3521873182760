/* .bg-breadcrumb {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../Images/car1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
} */
.breadcrumb-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; 
}
.bread-one {
    color: #9D8221 !important;
    font-size: 20px !important;
    font-weight: bold;
    text-decoration: none;
}
.bread-two {
    /* color: #001f3f !important; */
    font-size: 20px !important;
    font-weight: bold;
    text-decoration: none;
}
.bread-one:hover {
    color: gold !important;
}