.sticky-footer {
    display: none; 
  
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 5px;
    text-align: center;
    z-index: 1000;
  }
  
  .social-icons a {
    color: white;
    margin: 0 10px;
  }
  .social-icons {
    display: flex;
    justify-content: space-around; 
  }
  
  .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    background-color:transparent; 
  }
  
  .icon-name {
    display: block;
    margin-top: 5px; 
    color: white;
    font-size: 12px; 
  }
  .icon-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
  @media (max-width: 992px) {
    .sticky-footer {
      display: block;
    }
  }
  /*videos*/
  .videos-container {
    margin-top: 150px;
    margin-bottom: 150px;
  }