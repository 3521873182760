.copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    background: var(--bs-dark) !important;
}
.fuat {
  text-decoration: none !important;
  color: var(--main-color) !important;
}
@media (max-width: 992px) {
    .developed {
      margin-bottom: 100px;
    }
  }